import React from 'react'
import { Helmet } from 'react-helmet'

import { introduction } from 'data/data'

import { Layout, Header, SEO, Footer, Contact, Navbar } from 'components'

const MainPage = () => (
  <Layout>
    <SEO
      title='Otterwise - Tecnologia, ruptura e autonomia'
      description={introduction.text}
      author='Otterwise'
      url='otterwise.co'
      keywords='HTML, CSS, Javascript, React, Curso Online, Otterwise, Realocação Profissional, Programação Web'
    />
    <Helmet title='Home' defer={false} />
    <Navbar />
    <Header noImage title='Contato' text='Para mais informações, fale com a gente!' />
    <Contact />
    <Footer />
  </Layout>
)

export default MainPage
